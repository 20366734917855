import React from 'react';
import "./RectCard.css";

const RectCard = ({src}) => {
    return (
        <div className='rect-card'>
            <img src={src} />
        </div>
    )
}

export default RectCard;