import React from 'react';
import "./List.css";

const List = ({items, haveTag=false, numbered=false}) => {
    return (<ul className='list'>
        {items.map((item, index) => (
            <li className='list-item'>
                {numbered && <span className='numbering'>{index + 1}</span>}
                {haveTag && <span className='tag'>{item.tag}</span>}
                <p>{item.title}</p>
            </li>
        ))}
    </ul>)
}

export default List;