import React from 'react';
import Header from '../../Components/Header';
import RectCard from '../../Components/RectCard';
import logoSimpleBlack from '../../Assets/img/logoSimpleBlack.png';
import python from '../../Assets/img/python.png';
import github from '../../Assets/img/github.png';
import codingImage from '../../Assets/img/codingImage.png';
import arrowRight from '../../Assets/icons/arrowRight.svg';
import arrowRightLight from '../../Assets/icons/arrowRightLight.svg';
import List from '../../Components/List';
import {Link} from 'react-router-dom';
import './Home.css';

const modules = [
    {
        title: 'This is a module about python',
        tag: 'Python'
    }
]

const Home = () => {
    return (
        <>
            <Header />
            <section className='banner padding-container'>
                <p className='welcoming-sent'>
                    Your first step <br />to <img src={logoSimpleBlack} alt='X' />pertise
                </p>
                <div className='roadmaps'>
                    <p>Choose a Path:</p>
                    <div className='card-container'>
                        <Link to='/roadmap'><RectCard src={python} /></Link>
                        <Link to='/roadmap'><RectCard src={python} /></Link>
                        <Link to='/roadmap'><RectCard src={python} /></Link>
                        <Link to='/roadmap'><RectCard src={python} /></Link>
                        <Link to='/roadmap'><RectCard src={python} /></Link>
                        <Link to='/roadmap'><RectCard src={python} /></Link>
                        <Link to='/roadmap'><RectCard src={python} /></Link>
                        <Link to='/roadmap'><RectCard src={python} /></Link>
                        <p>Explore All Roadmaps <img src={arrowRight} /></p>
                    </div>
                    <p>Explore All Roadmaps <img src={arrowRight} /></p>
                </div>
            </section>
            <section className="modules padding-container">
                <h2 className='section-title'>Modules</h2>
                <List items={Array(8).fill(modules[0])} haveTag={true} />
                <p>See More <img src={arrowRightLight} /></p>
            </section>
            <section className='signup padding-container'>
                <div className='signup-form'>


                    <h2 className='section-title'>Sign Up to Save Your Progress</h2>
                    <form>
                        <div className='input-group'>
                            <label for='email'>Email*</label>
                            <input type="email" id='email' name='email'></input>
                        </div>
                        <div className='input-group'>
                            <label for='password'>Password*</label>
                            <input type='password' id='password' name='password'></input>
                        </div>

                        <p>*Required</p>
                        <button type='submit'>Sign Up</button>
                        <div className='alternative'>
                            <p>Or sign up using</p>
                            <div className='options'>
                                <div><img src={github} /></div>
                            </div>
                        </div>
                    </form>
                </div>
                <img src={codingImage} />
            </section>
        </>

    );
}

export default Home;