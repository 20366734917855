import React from 'react';
import Header from '../../Components/Header';
import python from '../../Assets/img/python.png';
import List from '../../Components/List';
import './Roadmap.css'

const modules = [
    {
        title: 'This is a module about python',
        tag: 'Python'
    }
];


const Roadmap = () => {
    return (
        <div className='roadmap'>
            <Header />
            <section className='roadmap-desc padding-container'>
                <img src={python} alt='Python'/>
                <p>Python is a high-level, versatile programming language known for its simplicity and readability, making it a popular choice for both beginners and experienced developers. With its clean syntax, Python allows for writing clear and concise code, reducing the complexity typically associated with programming. It supports multiple programming paradigms, including procedural, object-oriented, and functional programming. Python is widely used in various domains, such as web development, data analysis, artificial intelligence, machine learning, and automation. Its extensive libraries and frameworks, such as Django, NumPy, and TensorFlow, provide powerful tools for developers, helping them quickly build robust applications. Python’s community is vast, offering abundant resources, tutorials, and open-source contributions, making it an essential language in modern software development.</p>
            </section>
            <section className='padding-container'>
                <h2 className='section-title'>Section 1</h2>
                <List items={Array(5).fill(modules[0])} numbered={true} />
            </section>
            <section className='padding-container'>
                <h2 className='section-title'>Section 2</h2>
                <List items={Array(5).fill(modules[0])} numbered={true} />
            </section>
            <section className='padding-container'>
                <h2 className='section-title'>Section 3</h2>
                <List items={Array(5).fill(modules[0])} numbered={true} />
            </section>
            <section className='padding-container'>
                <h2 className='section-title'>Section 4</h2>
                <List items={Array(5).fill(modules[0])} numbered={true} />
            </section>
        </div>
    )
}

export default Roadmap;