import { React, useState } from "react";
import logo from "../Assets/img/logo.png";
import logoSimpleWhite from "../Assets/img/logoSimpleWhite.png"
import UKflag from "../Assets/img/UKflag.png"
import "./Header.css";
import {Link} from 'react-router-dom';

const Header = () => {
    const [dropdown, setDropdown] = useState(false);

    return (
        <header className="padding-container">
            <Link to='/' className='logo'><img src={logo} alt="CodeXpert" /><img src={logoSimpleWhite} alt="X" /></Link>
            <div className={`primary-menu " ${dropdown ? "primary-menu--dropped" : ""}`}>
                <p>Roadmaps</p>
                <p>About</p>
                <div className="secondary-menu">
                <span className="language-picker"><img src={UKflag} alt="UK" /></span>
                <span className="login-button">Login</span>
                <span className="signup-button">Sign Up</span>
            </div>
            </div>
            <div className="secondary-menu">
                <span className="language-picker"><img src={UKflag} alt="UK" /></span>
                <span className="login-button">Login</span>
                <span className="signup-button">Sign Up</span>
            </div>
            <div className={dropdown ? "primary-menu-dropdown--checked" : "primary-menu-dropdown"} onClick={() => {
                setDropdown(!dropdown)
            }}>
                <div className="hamburger-icon"></div>
            </div>
        </header>
    )
}

export default Header;